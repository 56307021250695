import React, { useEffect, useState,useRef  } from "react";
import { Card, Row, Col, Input, Button, DatePicker, Spin, Tabs,Form } from "antd";
import { connect } from "react-redux";
import PageTitle from "../../../layout/components/content/page-title";
import {
  clearData,
  isDrawerVisible,
  FilterInputB2CChangeValue,
} from "../../../store/dashboard/dashboardSlice";

import {
  BarChartOutlined,
  FilterOutlined,
  PieChartOutlined,
  ToolOutlined,
  SyncOutlined,
  DollarOutlined,
  TransactionOutlined,
  ReloadOutlined,
  ProjectOutlined,
} from "@ant-design/icons";
import TransactionCard from "./transactionCard";
import AnalyticsCard from "./analyticsCard";
import TotalOrdersCard from "./totalOrdersCard";
import CustomerOrderCard from "./customerOrderCard";
import { getCustomerOrdersCount, getDashboardCustmOrders, getDashboardSubscription, getMontlyOrderReport } from "../../../store/dashboard/dashboardAction";
import { useDispatch } from "react-redux";
import { Selector, Dispatch } from "../../../store/index";
import SubscriptionCard from "./subscriptionCard";
import moment, { min } from "moment";
import SwitchablePicker from "../../components/data-entry/datepicker/switchable";


function B2CDashboard(props) {
  const { TabPane } = Tabs;
  const { Search } = Input;
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("1")
  const [form] = Form.useForm();
  const customerCount = Selector("dashboard", "customerCount");
  const revenueCount = Selector("dashboard", "revenueCount");
  const cashCount = Selector("dashboard", "cashCount");
  const monthlyReport = Selector("dashboard", "monthlyReport");
  const dashboardParams = Selector("dashboard", "B2CdashboardParams");
  const customParams = Selector("dashboard", "customParams");
  const subscriParams = Selector("dashboard", "subscriParams");

const isInitialRender = useRef(true);

  function callback(key) {
    setActiveTab(key)
    if (key == 1) {
      Dispatch(dispatch, getDashboardCustmOrders, {
        ...customParams,
        start_date: dashboardParams?.start_date,
        end_date: dashboardParams?.end_date,

      });
    } else {
      Dispatch(dispatch, getDashboardSubscription, {
        ...subscriParams,
        start_date: dashboardParams?.start_date,
        end_date: dashboardParams?.end_date,
      });
    }
  }

  function disableDateRanges(range = { startDate: false, endDate: false }) {
    const { startDate, endDate } = range;
    return function disabledDate(current) {
      let startCheck = true;
      let endCheck = true;
      if (startDate) {
        startCheck =
          current &&
          current < moment(startDate + " 00:00:01", "YYYY-MM-DD HH:mm:ss");
      }
      if (endDate) {
        endCheck =
          current &&
          current >
            moment(
              moment(endDate).format("YYYY-MM-DD") + " 23:59:59",
              "YYYY-MM-DD HH:mm:ss"
            );
      }

      return (startDate && startCheck) || (endDate && endCheck);
    };
  }
  const onFinish = () => {
    dispatch(
      getDashboardCustmOrders({
        start_date: dashboardParams?.start_date,
        end_date: dashboardParams?.end_date,
      })
    );
    dispatch(
      getCustomerOrdersCount({
        start_date: dashboardParams?.start_date,
        end_date: dashboardParams?.end_date,
      })
    );
    Dispatch(dispatch, getDashboardSubscription, {
      ...subscriParams,
      start_date: dashboardParams?.start_date,
      end_date: dashboardParams?.end_date,
    });
  };
  const getDefaultDates = () => {
    const today = new Date();
    const yesterday = new Date();

    // Set yesterday by subtracting 1 day
    // yesterday.setDate(today.getDate() - 1);

    // Format dates as "YYYY-MM-DD"
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    return {
      start_date: formatDate(today),
      end_date: formatDate(yesterday),
    };
  };

  // Handle initial render logic
  useEffect(() => {
    if (isInitialRender.current) {
      // Get default dates
      const { start_date, end_date } = getDefaultDates();

      // Dispatch API calls for initial render
      dispatch(getDashboardCustmOrders({ ...customParams, start_date, end_date}));
      dispatch(getCustomerOrdersCount({start_date, end_date}));
      dispatch(getMontlyOrderReport({start_date, end_date}));

      // Set `dashboardParams` to default values to align state and params

      isInitialRender.current = false;
    }
  }, [dispatch]);

 useEffect(() => {
    if (isInitialRender.current) return;

    if (!dashboardParams?.start_date || !dashboardParams?.end_date) return;

    // Prevent duplicate calls for the same date range
    if (
      dashboardParams.start_date === dashboardParams.end_date
    ) {
      return;
    }

    // Update `dashboardParams` and make API calls for changed date range
    dispatch(getDashboardCustmOrders({ ...customParams, start_date: dashboardParams.start_date, end_date: dashboardParams.end_date }));
    dispatch(getCustomerOrdersCount({start_date: dashboardParams.start_date, end_date: dashboardParams.end_date}));
    dispatch(getDashboardSubscription({ ...subscriParams, start_date: dashboardParams.start_date, end_date: dashboardParams.end_date }));
  }, [dashboardParams.start_date, dashboardParams.end_date, customParams, subscriParams, dispatch]);
  
  return (

    <>
      <Row gutter={[12, 12]} className="hp-ecommerce-app-header hp-mb-10" >
         <Col
          span={22}
          style={{
            display: "flex",
            flexDirection: "row", // Change to row to align items horizontally
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <Col>
            <PageTitle pageTitle="Dashboard" goBack={false} />
          </Col>

          <Form
            form={form}
            layout="vertical"
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Col
              span={20}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Form.Item
                // label="Select Date"
                name="start_date"
                className="hp-mb-0"
              >
                <SwitchablePicker
                  id="start_date"
                  name="start_date"
                  style={{ width: "100%" }}
                  placeholder="Select Date"
                  PickerType={(e) => {
                    dispatch(
                      FilterInputB2CChangeValue({
                        key: "dateType",
                        value: e,
                      })
                    );
                  }}
                  PickerTypeValue={dashboardParams?.dateType}
                  disabledDate={disableDateRanges({
                    endDate: new Date(),
                  })}
                  value={dashboardParams?.start_date}
                  onChange={(date) => {
                    switch (dashboardParams?.dateType) {
                      case "week":
                        const startOfWeek = date
                          .clone()
                          .startOf("isoWeek")
                          .subtract(1, "day"); // Monday
                        const endOfWeek = date
                          .clone()
                          .endOf("isoWeek")
                          .subtract(1, "day"); // Saturday
                        dispatch(
                          FilterInputB2CChangeValue({
                            key: "start_date",
                            value: startOfWeek,
                          })
                        );
                        dispatch(
                          FilterInputB2CChangeValue({
                            key: "end_date",
                            value: endOfWeek,
                          })
                        );
                        break;

                      case "month":
                        const startOfMonth = date.clone().startOf("month"); // Add one day to the start of the month
                        const endOfMonth = date.clone().endOf("month"); // Keep the end of the month as is
                        dispatch(
                          FilterInputB2CChangeValue({
                            key: "start_date",
                            value: startOfMonth,
                          })
                        );
                        dispatch(
                          FilterInputB2CChangeValue({
                            key: "end_date",
                            value: endOfMonth,
                          })
                        );
                        break;

                      case "year":
                        const startOfYear = date.clone().startOf("year"); // Add one day to the start of the year
                        const endOfYear = date.clone().endOf("year"); // Keep the end of the year as is
                        dispatch(
                          FilterInputB2CChangeValue({
                            key: "start_date",
                            value: startOfYear,
                          })
                        );
                        dispatch(
                          FilterInputB2CChangeValue({
                            key: "end_date",
                            value: endOfYear,
                          })
                        );
                        break;

                      default:
                        // For default date picker
                        const endOfdate = date.clone().add(1, "day");
                        dispatch(
                          FilterInputB2CChangeValue({
                            key: "start_date",
                            value: date == null ? "" : date,
                          })
                        );
                        dispatch(
                          FilterInputB2CChangeValue({
                            key: "end_date",
                            value: endOfdate,
                          })
                        );
                    }
                  }}
                />
              </Form.Item>
            </Col>
            {/* <Col span={4}>
              <Button
                style={{ height: "25px", marginTop: "25px" }}
                onClick={onFinish}
              >
                Apply
              </Button>
            </Col> */}
          </Form>
        </Col>

      </Row>
      <Row gutter={[12, 12]}>
        <Col span={16} xs={24} sm={12} md={8} xl={8}>
          <TotalOrdersCard
            title=" Total Customer Orders"
            data={customerCount}
            icon={<PieChartOutlined />}
          />
        </Col>

        <Col span={16} xs={24} sm={12} md={8} xl={8}>
          <TransactionCard
            title="Revenue"
            data={revenueCount}
          />
        </Col>

        <Col span={16} xs={24} sm={12} md={8} xl={8}>
          <TransactionCard
            title="Cash Collection"
            data={cashCount}
          />
        </Col>
      </Row>

      <Row gutter={[12, 12]} className="hp-mt-10">
        <Col span={16} xs={24} sm={24} md={24} xl={24}>
          <AnalyticsCard
            title="Monthly Order Report"
            data={monthlyReport}
          />
        </Col>
      </Row>

      <Row gutter={[12, 12]} className="hp-mt-10">
        <Col span={24} xs={24} sm={24} md={24} xl={24}>
          <Card bodyStyle={{ padding: "10px" }}>
            <Tabs defaultActiveKey="1" onChange={callback}>
              <TabPane tab="Customer Order" key="1">
                <CustomerOrderCard />
              </TabPane>

              <TabPane tab="Subscription" key="2">
                <SubscriptionCard />
              </TabPane>
            </Tabs>
          </Card>
        </Col>

      </Row>
    </>
  );
}

function mapStateToProps({ dashboard, system }) {
  return {
    loading: dashboard.loading,
  };
}

export default connect(mapStateToProps, {
  isDrawerVisible,
})(B2CDashboard);

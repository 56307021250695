// import React, { useState } from "react";

// import SyntaxHighlighter from "react-syntax-highlighter";
// import { monoBlue } from "react-syntax-highlighter/dist/esm/styles/hljs";
// import { switchable } from "./code.js";

// import {
//   Card,
//   Row,
//   Col,
//   DatePicker,
//   TimePicker,
//   Space,
//   Select,
//   Button,
// } from "antd";
// import { RiCodeSSlashLine } from "react-icons/ri";

// const { Option } = Select;

// export default function SwitchablePicker() {
//   const [checkedCode, setCheckedCode] = useState(false);
//   const [codeClass, setCodeClass] = useState(false);

//   function toggleChecked() {
//     setTimeout(() => setCodeClass(!codeClass), 100);
//     setCheckedCode(!checkedCode);
//   }

//   function PickerWithType({ type, onChange }) {
//     if (type === "time") return <TimePicker onChange={onChange} />;
//     if (type === "date") return <DatePicker onChange={onChange} />;
//     return <DatePicker picker={type} onChange={onChange} />;
//   }

//   const [type, setType] = useState("time");

//   return (
//     <Card className="hp-border-color-black-40">
//       <Row>
//         <Col className="hp-mb-16" lg={12} span={20}>
//           <h4>Switchable Picker</h4>
//           <p className="hp-p1-body">
//             Switch in different types of pickers by Select.
//           </p>
//         </Col>

//         <Col lg={12} span={4} className="hp-text-right">
//           <Button
//             onClick={toggleChecked}
//             type="text"
//             icon={<RiCodeSSlashLine className="hp-text-color-black-80" />}
//           />
//         </Col>

//         <Col span={12}>
//           <Space size={16}>
//             <Select value={type} onChange={setType}>
//               <Option value="time">Time</Option>
//               <Option value="date">Date</Option>
//               <Option value="week">Week</Option>
//               <Option value="month">Month</Option>
//               <Option value="quarter">Quarter</Option>
//               <Option value="year">Year</Option>
//             </Select>
//             <PickerWithType
//               type={type}
//               onChange={(value) => console.log(value)}
//             />
//           </Space>
//         </Col>
//       </Row>

//       {checkedCode && (
//         <SyntaxHighlighter
//           language="javascript"
//           className={`show-code hp-mt-24 ${codeClass && "show-code-active"}`}
//           style={monoBlue}
//         >
//           {switchable}
//         </SyntaxHighlighter>
//       )}
//     </Card>
//   );
// }
import React, { useState } from "react";

import SyntaxHighlighter from "react-syntax-highlighter";
import { monoBlue } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { switchable } from "./code.js";

import {
  Card,
  Row,
  Col,
  DatePicker,
  TimePicker,
  Space,
  Select,
  Button,
} from "antd";
import { RiCodeSSlashLine } from "react-icons/ri";

const { Option } = Select;

export default function SwitchablePicker(props) {
  const {  onChange, onBlur, PickerType, PickerTypeValue ,value} = props;

  // const [checkedCode, setCheckedCode] = useState(false);
  // const [codeClass, setCodeClass] = useState(false);

  // function toggleChecked() {
  //   setTimeout(() => setCodeClass(!codeClass), 100);
  //   setCheckedCode(!checkedCode);
  // }

  function PickerWithType({ type,value, onChange }) {
    // if (type === "time") return <TimePicker onChange={onChange} />;
    if (type === "date") return <DatePicker value={value}  {...props} onChange={onChange} />;
    return <DatePicker value={value}  {...props} picker={type} onChange={onChange} />;
  }

  function handleChange(value){
    // setType(value)
    PickerType(value)
  }

  // const [type, setType] = useState("date");

  return (
    <Space size={24}>
      <Select value={PickerTypeValue} onChange={(e) => handleChange(e)}>
        {/* <Option value="time">Time</Option> */}
        <Option value="date">Date</Option>
        <Option value="week">Week</Option>
        <Option value="month">Month</Option>
        {/* <Option value="quarter">Quarter</Option> */}
        <Option value="year">Year</Option>
      </Select>
      <PickerWithType type={PickerTypeValue} 
    value={value}
      onChange={(value) => onChange(value)} />
    </Space>
  );
}




import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import {
	addParams,
	getList,
	getParamsList,
	postAdd,
	postEdit,
} from "../../helpers/Helper";
import { Modal } from "antd";
import {
	RiCheckboxCircleLine,
	RiAlertLine,
	RiInformationLine,
} from "react-icons/ri";


// B2C dashboard
export const getCustomerOrdersCount = createAsyncThunk(
	"/getCustomerOrdersCount",
	async (payload, { dispatch }) => {
		const params = addParams(payload);
		try {
			const response = await getParamsList(
				`/b2c/admindashboard/customerorders/count`,
				params
			);
			if (response) {
				const revenueCount = await dispatch(getRevenueCount(payload)).unwrap()
				return { response, revenueCount };

			} else {
				throw new Error(response);
			}
		} catch (error) {
			const revenueCount = await dispatch(getRevenueCount(payload)).unwrap()
			return error.message, { revenueCount };
			//  revenueCount;
		}
	}
);


export const getRevenueCount = createAsyncThunk(
	"/getRevenueCount",
	async (payload, { dispatch }) => {
		const params = addParams(payload);
		try {
			const response = await getParamsList(
				`/b2c/admindashboard/revenues/count`,
				params
			);
			if (response) {
				const cashCollection = await dispatch(getCashCollectionCount(payload)).unwrap()
				return { response, cashCollection };
			} else {
				throw new Error(response);
			}
		} catch (error) {
			const cashCollection = await dispatch(getCashCollectionCount(payload)).unwrap()
			return error.message, { cashCollection };
		}
	}
);

export const getCashCollectionCount = createAsyncThunk(
	"/getCashCollectionCount",
	async (payload) => {
		const params = addParams(payload);
		try {
			const response = await getParamsList(
				`/b2c/admindashboard/cashcollection_requests/count`,
				params
			);
			if (response) {
				return response;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);


export const getMontlyOrderReport = createAsyncThunk(
	"/getMontlyOrderReport",
	async (payload) => {
		const params = addParams(payload);
		try {
			const response = await getParamsList(
				`/b2c/admindashboard/monthlyorderreport/`,
				params
			);
			if (response) {
				return response;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);


export const getDashboardCustmOrders = createAsyncThunk(
	"/getDashboardCustmOrders",
	async (payload) => {
		const params = addParams(payload);
		try {
			const response = await getParamsList(
				`/b2c/admindashboard/customerorders/list/`,
				params
			);
			if (response) {
				return response;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);

export const getDashboardSubscription = createAsyncThunk(
	"/getDashboardSubscription",
	async (payload) => {
		const params = addParams(payload);
		try {
			const response = await getParamsList(
				`/b2c/admindashboard/subscriptions/list/`,
				params
			);
			if (response) {
				return response;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);

// B2B Dashboard



// get B2B dashboard Retailer Indents count
export const getB2BRetailerIndents = createAsyncThunk(
	"/getB2BRetailerIndents",
	async (payload, { dispatch }) => {
	  const params = addParams(payload);

	 const {start_date,end_date,dateType} = payload
	  try {
		// First API call
		const response = await getParamsList(
		  `/orders/admindashboard/retailer/indents/count`,
		  params
		);
  
		// Calculate previous period dates based on dateType
		let prevStartDate, prevEndDate;
  
		switch (dateType) {
		  case "date":
			prevStartDate = moment(start_date).subtract(1, "day").format("YYYY-MM-DD");
			prevEndDate = moment(end_date).subtract(1, "day").format("YYYY-MM-DD");
			break;
		  case "week":
			prevStartDate = moment(start_date).subtract(1, "week").startOf("week").format("YYYY-MM-DD");
			prevEndDate = moment(end_date).subtract(1, "week").endOf("week").format("YYYY-MM-DD");
			break;
		  case "month":
			prevStartDate = moment(start_date).subtract(1, "month").startOf("month").format("YYYY-MM-DD");
			prevEndDate = moment(end_date).subtract(1, "month").endOf("month").format("YYYY-MM-DD");
			break;
		  case "year":
			prevStartDate = moment(start_date).subtract(1, "year").startOf("year").format("YYYY-MM-DD");
			prevEndDate = moment(end_date).subtract(1, "year").endOf("year").format("YYYY-MM-DD");
			break;
		  default:
			throw new Error("Invalid dateType");
		}
  
		// Second API call for previous period
		const previousParams = addParams({ start_date: prevStartDate, end_date: prevEndDate });
		const previousResponse = await getParamsList(
		  `/orders/admindashboard/retailer/indents/count`,
		  previousParams
		);
  		
		  try {
			const distributorIndents = await dispatch(getB2BDistributorIndents(payload)).unwrap();
			return {
			  response: {
				today_count: response.count,
				previous_period_count: previousResponse.count,
			  },
			  distributorIndents,
			};
		  } catch (error) {
			console.error("Error in getB2BDistributorIndents:", error);
			throw error;
		  }
	  } catch (error) {
		throw error.message;
	  }
	}
  );
  

// get B2B dashboard total Distributor Indents count
export const getB2BDistributorIndents = createAsyncThunk(
    "/getB2BDistributorIndents",
    async (payload, { dispatch }) => {
        const params = addParams(payload);

        try {
            // First API call
            const response = await getParamsList(
                `/orders/admindashboard/distributor/indents/count`,
                params
            );
            // Extract date range and date type from payload
            const { start_date, end_date, dateType } = payload;

            // Calculate previous period dates based on dateType
            let prevStartDate, prevEndDate;

            switch (dateType) {
                case "date":
                    prevStartDate = moment(start_date).subtract(1, "day").format("YYYY-MM-DD");
                    prevEndDate = moment(end_date).subtract(1, "day").format("YYYY-MM-DD");
                    break;
                case "week":
                    prevStartDate = moment(start_date).subtract(1, "week").startOf("week").format("YYYY-MM-DD");
                    prevEndDate = moment(end_date).subtract(1, "week").endOf("week").format("YYYY-MM-DD");
                    break;
                case "month":
                    prevStartDate = moment(start_date).subtract(1, "month").startOf("month").format("YYYY-MM-DD");
                    prevEndDate = moment(end_date).subtract(1, "month").endOf("month").format("YYYY-MM-DD");
                    break;
                case "year":
                    prevStartDate = moment(start_date).subtract(1, "year").startOf("year").format("YYYY-MM-DD");
                    prevEndDate = moment(end_date).subtract(1, "year").format("YYYY-MM-DD");
                    break;
                default:
                    throw new Error("Invalid dateType");
            }

            // Second API call for previous period
            const previousParams = addParams({ start_date: prevStartDate, end_date: prevEndDate });
            const previousResponse = await getParamsList(
                `/orders/admindashboard/distributor/indents/count`,
                previousParams
            );

            if (response && previousResponse) {
                const distributorReceipts = await dispatch(getB2BDistributorReceipts(payload)).unwrap();
                return {
                    response: {
                        today_count: response.count,
                        previous_period_count: previousResponse.count,
                    },
                    distributorReceipts,
                };
            } else {
                throw new Error("Error fetching data");
            }
        } catch (error) {
            throw error.message;
        }
    }
);

// get B2B dashboard total Distributor Receipts count
export const getB2BDistributorReceipts = createAsyncThunk(
    "/getB2BDistributorReceipts",
    async (payload, { dispatch }) => {
        const params = addParams(payload);

        try {
            // First API call for current period
            const response = await getParamsList(
                `/orders/admindashboard/distributor/receipts/count`,
                params
            );

            // Extract date range and date type from payload
            const { start_date, end_date, dateType } = payload;

            // Calculate previous period dates based on dateType
            let prevStartDate, prevEndDate;

            switch (dateType) {
                case "date":
                    prevStartDate = moment(start_date).subtract(1, "day").format("YYYY-MM-DD");
                    prevEndDate = moment(end_date).subtract(1, "day").format("YYYY-MM-DD");
                    break;
                case "week":
                    prevStartDate = moment(start_date).subtract(1, "week").startOf("week").format("YYYY-MM-DD");
                    prevEndDate = moment(end_date).subtract(1, "week").endOf("week").format("YYYY-MM-DD");
                    break;
                case "month":
                    prevStartDate = moment(start_date).subtract(1, "month").startOf("month").format("YYYY-MM-DD");
                    prevEndDate = moment(end_date).subtract(1, "month").endOf("month").format("YYYY-MM-DD");
                    break;
                case "year":
                    prevStartDate = moment(start_date).subtract(1, "year").startOf("year").format("YYYY-MM-DD");
                    prevEndDate = moment(end_date).subtract(1, "year").endOf("year").format("YYYY-MM-DD");
                    break;
                default:
                    throw new Error("Invalid dateType");
            }

            // Second API call for previous period
            const previousParams = addParams({ start_date: prevStartDate, end_date: prevEndDate });
            const previousResponse = await getParamsList(
                `/orders/admindashboard/distributor/receipts/count`,
                previousParams
            );

            if (response && previousResponse) {
                const revenueCount = await dispatch(getB2BRevenueCount(payload)).unwrap();
                return {
                    response: {
                        today_count: response.count,
                        previous_period_count: previousResponse.count,
                    },
                    revenueCount,
                };
            } else {
                throw new Error("Error fetching data");
            }
        } catch (error) {
            throw error.message;
        }
    }
);


// get B2B dashboard total revenue count
export const getB2BRevenueCount = createAsyncThunk(
    "/getB2BRevenueCount", // Corrected action type
    async (payload, { dispatch }) => {
        const params = addParams(payload);

        try {
            // First API call for current period
            const response = await getParamsList(
                `/orders/admindashboard/ss/orderrevenues/count`,
                params
            );

            // Extract date range and date type from payload
            const { start_date, end_date, dateType } = payload;

            // Calculate previous period dates based on dateType
            let prevStartDate, prevEndDate;

            switch (dateType) {
                case "date":
                    prevStartDate = moment(start_date).subtract(1, "day").format("YYYY-MM-DD");
                    prevEndDate = moment(end_date).subtract(1, "day").format("YYYY-MM-DD");
                    break;
                case "week":
                    prevStartDate = moment(start_date).subtract(1, "week").startOf("week").format("YYYY-MM-DD");
                    prevEndDate = moment(end_date).subtract(1, "week").endOf("week").format("YYYY-MM-DD");
                    break;
                case "month":
                    prevStartDate = moment(start_date).subtract(1, "month").startOf("month").format("YYYY-MM-DD");
                    prevEndDate = moment(end_date).subtract(1, "month").endOf("month").format("YYYY-MM-DD");
                    break;
                case "year":
                    prevStartDate = moment(start_date).subtract(1, "year").startOf("year").format("YYYY-MM-DD");
                    prevEndDate = moment(end_date).subtract(1, "year").endOf("year").format("YYYY-MM-DD");
                    break;
                default:
                    throw new Error("Invalid dateType");
            }

            // Second API call for previous period
            const previousParams = addParams({ start_date: prevStartDate, end_date: prevEndDate });
            const previousResponse = await getParamsList(
                `/orders/admindashboard/ss/orderrevenues/count`,
                previousParams
            );

            if (response && previousResponse) {
                const distributorOrders = await dispatch(getB2BDistributorOrders(payload)).unwrap();
                return {
                    response: {
                        total_net_revenue: response.total_net_revenue,
                        previous_period_net_revenue: previousResponse.total_net_revenue,
                    },
                    distributorOrders,
                };
            } else {
                throw new Error("Error fetching data");
            }
        } catch (error) {
            throw error.message;
        }
    }
);

// get B2B dashboard total Distributor Orders count
export const getB2BDistributorOrders = createAsyncThunk(
	"/getB2BDistributorOrders",
	async (payload) => {
		const params = addParams(payload);
		try {
			const response = await getParamsList(
				`/orders/admindashboard/distributor/orders/count`,
				params
			);
			if (response) {
				return response;
			} else {
				throw new Error(response);
			}
		} catch (error) {
			return error.message;
		}
	}
);

// get B2B dashboard Distributor Order List
export const getDistributorOrdersList = createAsyncThunk(
	"/getDistributorOrdersList",
	async (payload) => {
		const params = addParams(payload);
		if(payload.start_date && payload.end_date){
			try {
				const response = await getParamsList(
					`/orders/admindashboard/distributor/orders/list/`,
					params
				);
				if (response) {
					return response;
				} else {
					throw new Error(response);
				}
			} catch (error) {
				return error.message;
			}
		}
	}
);
import React, { useEffect, useState,useRef  } from "react";
import {
  Card,
  Row,
  Col,
  Input,
  Button,
  DatePicker,
  Spin,
  Tabs,
  Form,
} from "antd";
import { connect } from "react-redux";
import PageTitle from "../../../layout/components/content/page-title";
import {
  isDrawerVisible,
  FilterInputChangeValue,
} from "../../../store/dashboard/dashboardSlice";

import { ProjectOutlined } from "@ant-design/icons";
import {
  getB2BRetailerIndents,
  getDistributorOrdersList,
} from "../../../store/dashboard/dashboardAction";
import { useDispatch } from "react-redux";
import { Selector, Dispatch } from "../../../store/index";
import RevenueCard from "./revenueCard";
import IndentsCard from "./indentsCard";
import DistributorOrderCard from "./distributorOrderCard";
import DistributorOrderList from "./distributorOrderList";
import SwitchablePicker from "../../components/data-entry/datepicker/switchable";
import moment, { min } from "moment";

function B2BDashboard(props) {
  const { TabPane } = Tabs;
  const { Search } = Input;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const previousParams = useRef(null);

  const totalRevenue = Selector("dashboard", "totalRevenue");
  const retailerIndent = Selector("dashboard", "retailerIndent");
  const distributorIndent = Selector("dashboard", "distributorIndent");
  const distributorReceipt = Selector("dashboard", "distributorReceipt");
  const distributorOrder = Selector("dashboard", "distributorOrder");
  const dashboardParams = Selector("dashboard", "dashboardParams");
  const isInitialRender = useRef(true); 

  function disableDateRanges(range = { startDate: false, endDate: false }) {
    const { startDate, endDate } = range;

    return function disabledDate(current) {
      let startCheck = true;
      let endCheck = true;
      if (startDate) {
        startCheck =
          current &&
          current < moment(startDate + " 00:00:01", "YYYY-MM-DD HH:mm:ss");
      }
      if (endDate) {
        endCheck =
          current &&
          current >
            moment(
              moment(endDate).format("YYYY-MM-DD") + " 23:59:59",
              "YYYY-MM-DD HH:mm:ss"
            );
      }

      return (startDate && startCheck) || (endDate && endCheck);
    };
  }
  const onFinish = () => {
    dispatch(
      getB2BRetailerIndents({
        start_date: dashboardParams?.start_date,
        end_date: dashboardParams?.end_date,
        dateType : "date",
      })
    );
    dispatch(
      getDistributorOrdersList({
        start_date: dashboardParams?.start_date,
        end_date: dashboardParams?.end_date,
      
      })
    );
  };

  const getDefaultDates = () => {
    const today = new Date();
    const yesterday = new Date();
  
    // Set yesterday by subtracting 1 day
    // yesterday.setDate(today.getDate() - 1);
  
    // Format dates as "YYYY-MM-DD"
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };
  
    return {
      start_date: formatDate(today),
      end_date: formatDate(yesterday),
    };
  };

  useEffect(() => {
    if (isInitialRender.current) {
      // Get default dates
      const { start_date, end_date } = getDefaultDates();

      // Dispatch API calls for initial render
      dispatch(
        getB2BRetailerIndents({
          start_date,
          end_date,
          dateType : "date",
        })
      );

      dispatch(
        getDistributorOrdersList({
          start_date,
          end_date,
        })
      );

      // Set `dashboardParams` to default values to align state and params
      previousParams.current = { start_date, end_date };
      isInitialRender.current = false;
    }
  }, [dispatch]);
  useEffect(() =>{
    form.setFieldsValue({
      start_date:dashboardParams.start_date,
    })
  },[dashboardParams])

  useEffect(() => {
    if (isInitialRender.current) return;

    if (!dashboardParams?.start_date || !dashboardParams?.end_date) return;

    // Prevent duplicate calls for the same date range
    if (
      previousParams.current?.start_date === dashboardParams.start_date &&
      previousParams.current?.end_date === dashboardParams.end_date
    ) {
      return;
    }

    // Update `previousParams` and make API calls for changed date range
    previousParams.current = dashboardParams;

    dispatch(
      getB2BRetailerIndents({
        start_date: dashboardParams.start_date,
        end_date: dashboardParams.end_date,
        dateType : dashboardParams?.dateType,
      })
    );

    dispatch(
      getDistributorOrdersList({
        start_date: dashboardParams.start_date,
        end_date: dashboardParams.end_date,
        dateType : dashboardParams?.dateType,
      })
    );
  }, [dashboardParams?.start_date, dashboardParams?.end_date, dispatch]);

  return (
    <>
      <Row gutter={[12, 12]} className="hp-ecommerce-app-header hp-mb-10">
        <Col
          span={22}
          style={{
            display: "flex",
            flexDirection: "row", // Change to row to align items horizontally
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <Col>
            <PageTitle pageTitle="Dashboard" goBack={false} />
          </Col>

          <Form
            form={form}
            layout="vertical"
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Col
              span={20}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Form.Item
                // label="Select Date"
                name="start_date"
                className="hp-mb-0"
              >
                <SwitchablePicker
                  id="start_date"
                  name="start_date"
                  style={{ width: "100%" }}
                  placeholder="Select Date"
                  PickerType={(e) => {
                    dispatch(
                      FilterInputChangeValue({
                        key: "dateType",
                        value: e,
                      })
                    );
                  }}
                  PickerTypeValue={dashboardParams?.dateType}
                  disabledDate={disableDateRanges({
                    endDate: new Date(),
                  })}
                  value={dashboardParams?.start_date}
                  onChange={(date) => {
                    switch (dashboardParams?.dateType) {
                      case "week":
                        const startOfWeek = date
                          .clone()
                          .startOf("isoWeek")
                          .subtract(1, "day"); // Monday
                        const endOfWeek = date
                          .clone()
                          .endOf("isoWeek")
                          .subtract(1, "day"); // Saturday
                        dispatch(
                          FilterInputChangeValue({
                            key: "start_date",
                            value: startOfWeek,
                          })
                        );
                        dispatch(
                          FilterInputChangeValue({
                            key: "end_date",
                            value: endOfWeek,
                          })
                        );
                        break;

                      case "month":
                        const startOfMonth = date.clone().startOf("month"); // Add one day to the start of the month
                        const endOfMonth = date.clone().endOf("month"); // Keep the end of the month as is
                        dispatch(
                          FilterInputChangeValue({
                            key: "start_date",
                            value: startOfMonth,
                          })
                        );
                        dispatch(
                          FilterInputChangeValue({
                            key: "end_date",
                            value: endOfMonth,
                          })
                        );
                        break;

                      case "year":
                        const startOfYear = date.clone().startOf("year") // Add one day to the start of the year
                        const endOfYear = date.clone().endOf("year"); // Keep the end of the year as is
                        dispatch(
                          FilterInputChangeValue({
                            key: "start_date",
                            value: startOfYear,
                          })
                        );
                        dispatch(
                          FilterInputChangeValue({
                            key: "end_date",
                            value: endOfYear,
                          })
                        );
                        break;

                      default:
                        dispatch(
                          FilterInputChangeValue({
                            key: "start_date",
                            value: date == null ? "" : date,
                          })
                        );
                        dispatch(
                          FilterInputChangeValue({
                            key: "end_date",
                            value: date == null ? "" : date,
                          })
                        );
                    }
                  
                  }}
                />
              </Form.Item>
            </Col>
            {/* <Col span={4}>
              <Button
                style={{ height: "25px", marginTop: "5px" }}
                onClick={onFinish}
              >
                Apply
              </Button>
            </Col> */}
          </Form>
        </Col>
      </Row>

      <Row gutter={[12, 12]}>
        <Col span={16} xs={24} sm={12} md={12} lg={8} xl={8}>
          <IndentsCard
            title="Retailer Indents"
            data={retailerIndent}
            icon={<ProjectOutlined />}
          />
        </Col>
        <Col span={16} xs={24} sm={12} md={12} lg={8} xl={8}>
          <IndentsCard
            title="Distributor Indents"
            data={distributorIndent}
            icon={<ProjectOutlined />}
          />
        </Col>
        <Col span={16} xs={24} sm={12} md={12} lg={8} xl={8}>
          <IndentsCard
            title="Distributor Receipts"
            data={distributorReceipt}
            icon={<ProjectOutlined />}
          />
        </Col>

        <Col span={16} xs={24} sm={12} md={12} lg={8} xl={8}>
          <DistributorOrderCard
            title="Distributor Orders"
            data={distributorOrder}
          />
        </Col>
        <Col span={16} xs={24} sm={12} md={12} lg={8} xl={8}>
          <RevenueCard title="Revenue" total={totalRevenue} />
        </Col>
      </Row>

      <Row gutter={[12, 12]} className="hp-mt-10">
        <Col span={16} xs={24} sm={24} md={24} xl={24}>
          <DistributorOrderList />
        </Col>
      </Row>
    </>
  );
}

function mapStateToProps({ dashboard, system }) {
  return {
    loading: dashboard.loading,
  };
}

export default connect(mapStateToProps, {
  isDrawerVisible,
  FilterInputChangeValue,
})(B2BDashboard);
